import { Box, Flex, Link, Spacer, Text } from "@chakra-ui/layout"
import { navigate } from "gatsby-link"
import React from "react"
import Image from "../Image/Image"

const Navbar = () => {
  return (
    <Box pb={["sm", "sm", "md", "md"]}>
      <Flex
        flexDir={["column", "column", "row", "row"]}
        align="center"
        justify="center"
      >
        <Text
          fontSize={25}
          fontWeight={600}
          onClick={() => navigate("/")}
          cursor="pointer"
        >
          Push K. Production
        </Text>
        <Spacer />
        <Flex
          flexDir="row"
          align="center"
          justify="center"
          mt={["sm", "sm", 0, 0]}
        >
          <Link fontSize={18} mx={["md", "md", "sm", "sm"]} href="/store">
            Store
          </Link>
          <Link fontSize={18} mx={["md", "md", "sm", "sm"]} href="/contact">
            Contact
          </Link>
        </Flex>

        <Box
          w={50}
          cursor="pointer"
          transition="all 0.4s ease-in-out"
          _hover={{ transform: "scale(1.2)" }}
          mt={["sm", "sm", 0, 0]}
          display={["none", "none", "block", "block"]}
        >
          <Link href="https://www.instagram.com/pushk.production/" isExternal>
            <Image filename="instagram.png" alt="instagram" />
          </Link>
        </Box>
      </Flex>
    </Box>
  )
}

export default Navbar
