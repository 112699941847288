import { Box, Text } from "@chakra-ui/layout"
import React from "react"

const Footer = () => {
  return (
    <Box textAlign="center" mt="md">
      <Text fontSize={13}>
        © {new Date().getFullYear()} Push K. Production -{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/0xpierre.dev"
        >
          @0xpierre.dev
        </a>
      </Text>
    </Box>
  )
}

export default Footer
