import { Container } from "@chakra-ui/layout"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import React from "react"
import { Helmet } from "react-helmet"
import customTheme from "../../utils/theme"
import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import "./Layout.scss"

const theme = extendTheme(customTheme)

const Layout = props => {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <title>PushKProduction - Official Website</title>
        <description>PushKProduction - Official Website © 0xPierre</description>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="keywords" content="PushKProduction" />
        <meta name="description" content="PushKProduction - Official Website" />
        <meta name="author" content="@0xpierre.dev" />
        <meta name="copyright" content="© 0xPierre Inc." />

        <meta
          name="twitter:site"
          content="PushKProduction - Official Website"
        />
        <meta name="twitter:creator" content="@0xpierre.dev" />
        <meta
          name="twitter:description"
          content="PushKProduction - Official Website"
        />
        <meta name="robots" content="all" />
        <meta
          name="twitter:title"
          content="PushKProduction - Official Website"
        />
        <meta name="og:title" content="PushKProduction - Official Website" />
        <meta name="og:url" content="https://pushkproduction.netlify.app/" />
        <meta
          name="image"
          content="https://pushkproduction.netlify.app/favicon.png"
        />
        <meta
          name="og:image"
          content="https://pushkproduction.netlify.app/favicon.png"
        />
        <meta
          name="twitter:image"
          content="https://pushkproduction.netlify.app/favicon.png"
        />
        <meta name="og:image:type" content="image/png" />
        <meta
          name="og:description"
          content="PushKProduction - Official Website"
        />
        <meta
          name="og:site_name"
          content="PushKProduction - Official Website"
        />
      </Helmet>

      <Container maxW={["100%", "100%", "75%", "75%"]} py="md" color="white">
        <Navbar />
        {props.children}
        <Footer />
      </Container>
    </ChakraProvider>
  )
}

export default Layout
